import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { getChartCustom } from '../interfaces/get-data-chart.interface';
import { AvisosComponent } from 'src/app/dialogs/avisos/avisos.component';
import { MatDialog } from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(private http: HttpClient, private dialogo: MatDialog) { }

  private getDevicesUser(user) {
    return this.http.post(environment.getDevices, user)
  }

  public getFormartDataDevices(user) {
    return this.getDevicesUser(user).pipe(
      map((data: any) => {
        let devices = Object.keys(data);
        let correctData = []
        localStorage.setItem('devicesUser', (devices).toString())
        devices.forEach(device => {
          let dato = data[device];
          dato.id = device;
          correctData.push(dato)
        });
        if (correctData.length > 0) {
          return correctData;
        }
        else {
          this.dialogo.open(AvisosComponent, {
            width: '350px',
            height: '200px',
            data: { titulo: 'Ha ocurrido un error.', contenido: 'Parece que no hay dispositivos asociados a este usuario.' },
            panelClass: 'dialogo'
          })
        }
      }
      ))
  }

  public getFormartDevicesGroupFacility(user) {
    return this.getDevicesUser(user).pipe(
      map((data: any) => {
        let devices = Object.keys(data);
        let correctData = []

        devices.forEach(device => {
          let dato = data[device];
          dato.id = device;
          correctData.push(dato)
        });
        return correctData;
      }
      )
    )
  }

  private getValuesChart(queryChart: getChartCustom) {
    return this.http.post(environment.getChart, queryChart)
  }

  public getDataChart(queryChart: getChartCustom) {
    return this.getValuesChart(queryChart)
      .pipe(
        map((data: any) => {
          if (data.results.length > 0) {
            data.results.reverse()
            const labels = [];
            var values = [];
            var dates = [];
            var val = [];
            const device = data.device

            Object.keys(data.results[0]).forEach(element => {
              let resultados = data.results;

              if (element !== 'time_stamp') { labels.push(element); }

              resultados.map((resultado) => { val.push(resultado[element]) })
            });

            for (let i = 0; i < val.length; i += data.results.length) {
              let pedazo = val.slice(i, i + data.results.length);
              if (i === 0) {
                dates = pedazo;
              } else {
                values.push(pedazo);
              }
            }
            return { device, labels, dates, values }
          }
          else {
            return 'Error';
          }

        })
      )
  }

  public getDataMultipleChart(queryChart: getChartCustom, numCharts : number) {
    return this.getValuesChart(queryChart)
      .pipe(
        map((data: any) => {
          if (data.results.length > 0) {
            const date = data.results[0]['time_stamp']
            const labels = [];
            var values = [];
            var val = [];
            var label=[]
            const device = data.device
            const sizeSlices = Object.keys(data.results[0]).length/numCharts;

            Object.keys(data.results[0]).forEach(element => {
              if (element !== 'time_stamp') {
                label.push(element);          
              }
            });

            label.forEach(element => {
              val.push([data.results[0][element], data.results[1][element]])
            });

            for (let i = 0; i < label.length; i += sizeSlices) {
              let pedazoEtiqueta = label.slice(i, i + sizeSlices);
              labels.push(pedazoEtiqueta);

              let pedazoValor = val.slice(i, i + sizeSlices);
              values.push(pedazoValor);
            }
            
            return { device, labels, date, values }
          }
          else {
            return 'Error';
          }

        })
      )
  }

  public addDeviceUser(data) {
    return this.http.post(environment.addDeviceUser, data)
  }
}
